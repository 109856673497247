import React from 'react'

// Our global theme context with default values
export const LanguageContext = React.createContext({
  language: "english",
  setLanguage: () => {},
})

// Theme provider component with state
const LanguageProvider = props => {
  const [language, setLanguage] = React.useState("english")
  const value = { language, setLanguage }

  return (
    <LanguageContext.Provider value={value}>
      {props.children}
    </LanguageContext.Provider>
  )
}

// Exports a LanguageProvider wrapper
export default ({ element }) => <LanguageProvider>{element}</LanguageProvider>
